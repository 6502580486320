import ml5 from "ml5";
import React, { useEffect, useRef, useState } from "react";
import {Canvas} from './Canvas'
import Stack from '@mui/material/Stack';

export default function AudioPlayer() {
    const rightOfCanvas = useRef(null);
    const [stream, setStream] = useState(null);
    const [midi, setMidi] = useState(null);
    const [midiChanged, setMidiChanged] = useState(0);

    const scale = {
        16:"C0",
        17:"C#0/Db0",
        18:"D0",
        19:"D#0/Eb0",
        21:"E0",
        22:"F0",
        23:"F#0/Gb0",
        25:"G0",
        26:"G#0/Ab0",
        28:"A0",
        29:"A#0/Bb0",
        31:"B0",
        33:"C1",
        35:"C#1/Db1",
        37:"D1",
        39:"D#1/Eb1",
        41:"E1",
        44:"F1",
        46:"F#1/Gb1",
        49:"G1",
        52:"G#1/Ab1",
        55:"A1",
        58:"A#1/Bb1",
        62:"B1",
        65:"C2",
        69:"C#2/Db2",
        73:"D2",
        78:"D#2/Eb2",
        82:"E2",
        87:"F2",
        93:"F#2/Gb2",
        98:"G2",
        104:"G#2/Ab2",
        110:"A2",
        117:"A#2/Bb2",
        123:"B2",
        131:"C3",
        139:"C#3/Db3",
        147:"D3",
        156:"D#3/Eb3",
        165:"E3",
        175:"F3",
        185:"F#3/Gb3",
        196:"G3",
        208:"G#3/Ab3",
        220:"A3",
        233:"A#3/Bb3",
        247:"B3",
        262:"C4",
        277:"C#4/Db4",
        294:"D4",
        311:"D#4/Eb4",
        330:"E4",
        349:"F4",
        370:"F#4/Gb4",
        392:"G4",
        415:"G#4/Ab4",
        440:"A4",
        466:"A#4/Bb4",
        494:"B4",
        523:"C5",
        554:"C#5/Db5",
        587:"D5",
        622:"D#5/Eb5",
        659:"E5",
        698:"F5",
        740:"F#5/Gb5",
        784:"G5",
        831:"G#5/Ab5",
        880:"A5",
        932:"A#5/Bb5",
        988:"B5",
        1047:"C6",
        1109:"C#6/Db6",
        1175:"D6",
        1245:"D#6/Eb6",
        1319:"E6",
        1397:"F6",
        1480:"F#6/Gb6",
        1568:"G6",
        1661:"G#6/Ab6",
        1760:"A6",
        1865:"A#6/Bb6",
        1976:"B6",
        2093:"C7",
        2217:"C#7/Db7",
        2349:"D7",
        2489:"D#7/Eb7",
        2637:"E7",
        2794:"F7",
        2960:"F#7/Gb7",
        3136:"G7",
        3322:"G#7/Ab7",
        3520:"A7",
        3729:"A#7/Bb7",
        3951:"B7",
        4186:"C8",
        4435:"C#8/Db8",
        4699:"D8",
        4978:"D#8/Eb8",
        5274:"E8",
        5588:"F8",
        5920:"F#8/Gb8",
        6272:"G8",
        6645:"G#8/Ab8",
        7040:"A8",
        7459:"A#8/Bb8",
        7902:"B8"
    }
    
    const order = {
      "C0":1,
      "C#0/Db0":2,
      "D0":3,
      "D#0/Eb0":4,
      "E0":5,
      "F0":6,
      "F#0/Gb0":7,
      "G0":8,
      "G#0/Ab0":9,
      "A0":10,
      "A#0/Bb0":11,
      "B0":12,
      "C1":13,
      "C#1/Db1":14,
      "D1":15,
      "D#1/Eb1":16,
      "E1":17,
      "F1":18,
      "F#1/Gb1":19,
      "G1":20,
      "G#1/Ab1":21,
      "A1":22,
      "A#1/Bb1":23,
      "B1":24,
      "C2":25,
      "C#2/Db2":26,
      "D2":27,
      "D#2/Eb2":28,
      "E2":29,
      "F2":30,
      "F#2/Gb2":31,
      "G2":32,
      "G#2/Ab2":33,
      "A2":34,
      "A#2/Bb2":35,
      "B2":36,
      "C3":37,
      "C#3/Db3":38,
      "D3":39,
      "D#3/Eb3":40,
      "E3":41,
      "F3":42,
      "F#3/Gb3":43,
      "G3":44,
      "G#3/Ab3":45,
      "A3":46,
      "A#3/Bb3":47,
      "B3":48,
      "C4":49,
      "C#4/Db4":50,
      "D4":51,
      "D#4/Eb4":52,
      "E4":53,
      "F4":54,
      "F#4/Gb4":55,
      "G4":56,
      "G#4/Ab4":57,
      "A4":58,
      "A#4/Bb4":59,
      "B4":60,
      "C5":61,
      "C#5/Db5":62,
      "D5":63,
      "D#5/Eb5":64,
      "E5":65,
      "F5":66,
      "F#5/Gb5":67,
      "G5":68,
      "G#5/Ab5":69,
      "A5":70,
      "A#5/Bb5":71,
      "B5":72,
      "C6":73,
      "C#6/Db6":74,
      "D6":75,
      "D#6/Eb6":76,
      "E6":77,
      "F6":78,
      "F#6/Gb6":79,
      "G6":80,
      "G#6/Ab6":81,
      "A6":82,
      "A#6/Bb6":83,
      "B6":84,
      "C7":85,
      "C#7/Db7":86,
      "D7":87,
      "D#7/Eb7":88,
      "E7":89,
      "F7":90,
      "F#7/Gb7":91,
      "G7":92,
      "G#7/Ab7":93,
      "A7":94,
      "A#7/Bb7":95,
      "B7":96,
      "C8":97,
      "C#8/Db8":98,
      "D8":99,
      "D#8/Eb8":100,
      "E8":101,
      "F8":102,
      "F#8/Gb8":103,
      "G8":104,
      "G#8/Ab8":105,
      "A8":106,
      "A#8/Bb8":107,
      "B8":108
    }

    useEffect(() => {
        // Function to get the user's microphone stream
        const getMicrophoneStream = async () => {
          try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            setStream(stream);
            console.log("!!!")
          } catch (error) {
            console.error('Error accessing microphone:', error);
          }
        };
    
        // Call the function to get the microphone stream
        getMicrophoneStream();
    
        // Cleanup function to stop the stream when component unmounts
        return () => {
          if (stream) {
            stream.getTracks().forEach(track => {
              track.stop();
            });
          }
        };
    }, []); // Empty dependency array ensures this effect runs only once

    useEffect(() => {
        if(stream!=null && stream!=undefined){
            const audioContext = new AudioContext();
    
            let model_url = 'https://cdn.jsdelivr.net/gh/ml5js/ml5-data-and-models/models/pitch-detection/crepe/'
        
            const pitch = ml5.pitchDetection(
            model_url,
            audioContext,
            stream,
            modelLoaded,
            );
        
            // When the model is loaded
            function modelLoaded() {
            console.log('Model Loaded!');
            getPitch();
            }

            function getNote(frequency) {
                if(scale[Math.round(frequency)]){
                    return scale[Math.round(frequency)]
                }else{
                    let roundedFreqH = Math.round(frequency)
                    let roundedFreqL = Math.round(frequency)

                    let countH=0
                    let countL=0

                    while(!scale[roundedFreqH]){
                        roundedFreqH++
                        countH++
                    }
                    while(!scale[roundedFreqL]){
                        roundedFreqL--
                        countL++
                    }
                    return scale[countH<=countL?roundedFreqH:roundedFreqL];
                }
            }


    
            function getPitch() {
              pitch.getPitch(function(err, frequency) {
                setTimeout(() => {

                if (frequency) {
                  let currentNote = getNote(frequency)
                  setMidi(currentNote)
                  setMidiChanged(prev => prev+1)
                }


                getPitch();
                }, 500)

              })
            }

        }
    }, [stream]); // Empty dependency array ensures this effect runs only once

    // useEffect(()=>{
    //   if(rightOfCanvas.current){
    //     rightOfCanvas.current.scrollIntoView()
    //   }
    // }, [midi])
    
    const AudioRecorder = () => {
        return (
          <div>
            {stream ? (
              <p>Microphone stream is active</p>
            ) : (
              <p>Waiting for microphone access...</p>
            )}
          </div>
        );
    };

    const MIDI = () => {
        return (
          <div>
            {midi}
          </div>
        );
    };

    return(
        <div style={{width:"100vw", height:"100vh", backgroundColor:"#733e9c", display:"flex", justifyContent:"center", alignItems:"center"}}> 


          <Stack spacing={2}>
            {/* <audio src={require('./audio.mp3')} controls></audio> */}
            <AudioRecorder/>
            &nbsp;
            <MIDI/>
            <div width="300px" height="300px">
              <div width="300px" style={{maxWidth:"500px", overflowX: 'scroll'}}>
                <div style={{display:"inline-box", width:"1010px"}} >
                  <Canvas width="1000px" height="500px" midi={midi} midiChanged={midiChanged} order={order}/>
                  <div ref={rightOfCanvas} style={{backgroundColor:"blue", width:"0px", height:"300px", float:"right"}}/>
                </div>
              </div>
            </div>
            {/* <audio-player title="Ariana Grande Acapella"
	              src={require('../media/audio.mp3')}
	              bar-width="5"
	              bar-gap="2"
	              preload autoplay
	               ></audio-player>
            <script src={require('../js/player.js')}></script> */}
          </Stack>
        </div>
    );
}