import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import "../css/background.scss"
import Stack from 'react-bootstrap/Stack';


export const  LogIn = (props) =>{
  const navigate = useNavigate();
  
  const [validated, setValidated] = useState(false);
  const [form, setForm] = useState({form:{
    name:"",
    email:"",
    description:"",
    time:""
  }})

  async function updateSiteTraffic(){
    // needs to wait for api to complete before completing the page 
    // otherwise reload interupts POST api
      fetch("https://marielenaperez.com/updateSiteTraffic", {method:'POST',body:JSON.stringify(form),headers:{'Content-Type': 'application/json'}})
      .then(()=>{
        navigate(0)
      })
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    console.log(JSON.stringify(form.name)+JSON.stringify(form.email)+JSON.stringify(form.description)+JSON.stringify(form.time))    
    const form2 = event.currentTarget

    if (form2.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
    }

    setValidated(true)
    window.localStorage.setItem("authentication", true)
    updateSiteTraffic()

  };

  const handleChange = (event) => {
    setForm((previousValues) => ({
      ...previousValues,
      [event.target.name]: event.target.value,
      ["time"]: new Date().toLocaleString()+" "+new Date().toString().match(/\(([^\)]+)\)$/)[1]
    }));
  };

  return (
    <div class="bubbles" style={{height:"100vh", width:"100vw"}}>
      <div>
      <div class="bubble"></div>
      <div class="bubble"></div>
      <div class="bubble"></div>
      <div class="bubble"></div>
      <div class="bubble"></div>
      <div class="bubble"></div>
      <div class="bubble"></div>
      <div class="bubble"></div>
      <div class="bubble"></div>
      <div class="bubble"></div>
      <div class="bubble"></div>
      <div class="bubble"></div>
      <div class="bubble"></div>
      <div class="bubble"></div>
      <div class="bubble"></div>
      <div class="bubble"></div>
      <div class="bubble"></div>
      <div class="bubble"></div>
      <div class="bubble"></div>
      <div class="bubble"></div>
      <div class="bubble"></div>
      <div class="bubble"></div>
      <div class="bubble"></div>
      <div class="bubble"></div>
      <div class="bubble"></div>
      <div class="bubble"></div>
      <div class="bubble"></div>
      <div class="bubble"></div>
      <div class="bubble"></div>
      <div class="bubble"></div>
      <div class="bubble"></div>
      <div class="bubble"></div>
      <div class="bubble"></div>
      <div class="bubble"></div>
      <div class="bubble"></div>
      <div class="bubble"></div>
      <div class="bubble"></div>
      <div class="bubble"></div>
      <div class="bubble"></div>
      <div class="bubble"></div>
      <div class="bubble"></div>
      <div class="bubble"></div>
      <div class="bubble"></div>
      <div class="bubble"></div>
      <div class="bubble"></div>
      <div class="bubble"></div>
      <div class="bubble"></div>
      <div class="bubble"></div>
      <div class="bubble"></div>
      <div class="bubble"></div>
      </div>

      <div style={{overflow:"scroll", width:"100%", height:"100%"}}>
      <div style={{height:"80%", width:"min(100vw, 500px)", margin:"auto", marginTop:"5%"}}>

      <div style={{aspectRatio:"1/1", width:"200px", marginLeft:"auto", marginRight:"auto"}}>
          <img src={require('../images/resumePicSq1.jpg')} style={{borderRadius:"100%", width:"100%", height:"100%", objectFit: "cover"}}></img>  
      </div>

      <Stack gap={2} style={{marginTop:"5%"}} >
          <h1 style={{textAlign:"center", margin:"0%", fontSize:"min(4vw), 30px)", padding:"0%"}}>Welcome to Marielena Perez's Portfolio</h1>
          <h4 style={{textAlign:"center", margin:"0%", fontSize:"min(1vw), 5px)", padding:"0%"}}>Please fill the form below in order to proceed.</h4>
      </Stack>

      <Container style={{marginTop:"5%", marginBottom:"5%"}}>
        <Row className="mb-1" >            
          <Form noValidate validated={validated} onSubmit={handleSubmit} style={{height:"100%"}}>

            <Row className="mb-4">
              <Form.Group md="4" controlId="validationCustom01">
                <Form.Label style={{color: "white"}}><span style={{color: "red"}}>*  </span>Name</Form.Label>
                <Form.Control
                  required
                  type="name"
                  name="name"
                  value={form.name}
                  onChange={handleChange} 
                  autoComplete="on"
                  placeholder="John Doe"
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row className="mb-4">

              <Form.Group md="4" controlId="validationCustomUsername">
                <Form.Label style={{color: "white"}}><span style={{color: "red"}}>*  </span>Email</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    required
                    type="email"
                    name="email"
                    value={form.email}
                    onChange={handleChange} 
                    placeholder="your@email.com"
                    autoComplete="on"
                    aria-describedby="inputGroupPrepend"
                  />
                  <Form.Control.Feedback type="invalid">
                    Please type any valid email.
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Row>

            <Row className="mb-4">
              <Form.Group className="mb-3">
              <Form.Label style={{color: "white"}}>Description for Visit</Form.Label>
                <Form.Control
                  type="text"
                  name="description"
                  value={form.description}
                  onChange={handleChange}
                  aria-describedby="visitDescription"
                  placeholder="Recruiter"
                />
              </Form.Group>
            </Row>

            <Row className="mb-4">
                <Button type="submit" variant="outline-info" style={{width:"20vw", margin:"auto"}}>Submit</Button>
            </Row>
          </Form>
        </Row>
      </Container>
      </div>
      </div>
    </div>
  );
}
