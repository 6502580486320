import "../css/background.scss"
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';

export default function Raytracer() {
    return(
        <div class="bubbles" style={{height:"100vh", width:"100vw"}}>
            <div>
            <div class="bubble"></div>
            <div class="bubble"></div>
            <div class="bubble"></div>
            <div class="bubble"></div>
            <div class="bubble"></div>
            <div class="bubble"></div>
            <div class="bubble"></div>
            <div class="bubble"></div>
            <div class="bubble"></div>
            <div class="bubble"></div>
            <div class="bubble"></div>
            <div class="bubble"></div>
            <div class="bubble"></div>
            <div class="bubble"></div>
            <div class="bubble"></div>
            <div class="bubble"></div>
            <div class="bubble"></div>
            <div class="bubble"></div>
            <div class="bubble"></div>
            <div class="bubble"></div>
            <div class="bubble"></div>
            <div class="bubble"></div>
            <div class="bubble"></div>
            <div class="bubble"></div>
            <div class="bubble"></div>
            <div class="bubble"></div>
            <div class="bubble"></div>
            <div class="bubble"></div>
            <div class="bubble"></div>
            <div class="bubble"></div>
            <div class="bubble"></div>
            <div class="bubble"></div>
            <div class="bubble"></div>
            <div class="bubble"></div>
            <div class="bubble"></div>
            <div class="bubble"></div>
            <div class="bubble"></div>
            <div class="bubble"></div>
            <div class="bubble"></div>
            <div class="bubble"></div>
            <div class="bubble"></div>
            <div class="bubble"></div>
            <div class="bubble"></div>
            <div class="bubble"></div>
            <div class="bubble"></div>
            <div class="bubble"></div>
            <div class="bubble"></div>
            <div class="bubble"></div>
            <div class="bubble"></div>
            <div class="bubble"></div>
            </div>

            <div style={{width:"100vw", height:"100vh", padding:"5% 10% 10% 10%", overflow:"auto", textAlign:"center"}}>
                <Button className="mb-3" 
                href="https://observablehq.com/d/513153980ae5bbd7" 
                target="_blank" 
                variant="outline-light" 
                style={{fontSize:"calc(1.3rem + .6vw)"}}>
                    Raytracer Demo
                </Button>

                <Stack style={{alignItems:"center"}}>
                    <h3 style={{textAlign:"center", width:"min(90vw, 800px)", fontSize:"calc(1rem + .6vw)", padding:"0%"}}>
                        <br/>Instructions and Configurations:<br/><br/>
                        This project is hosted on Observable.<br/>
                        A JavaScript hosting site for building visualization projects.<br/>
                    </h3>

                    <img src={require('../images/prerender.png')} style={{aspectRatio:"1/1", width:"min(90vw, 800px)", height:"auto", objectFit: "cover", zIndex:"0", padding:"5% 0% 5% 0%"}}></img>        
                    <h3 style={{textAlign:"left", width:"min(90vw, 800px)", fontSize:"calc(1rem + .6vw)"}}>
                        Initially the graphic pane will be empty.
                        <br/><br/>
                    </h3>

                    <h3 style={{textAlign:"left", width:"min(90vw, 800px)", fontSize:"calc(1rem + .6vw)", padding:"0%"}}>
                        To start the render, adjust the following values:
                        <br/><br/>
                        1. "Sample per pixel" determines render quality and time will depend on this.
                        <br/><br/>
                        2. "Select a Scene" the default will be a classic Cornell Box scene.
                        <br/><br/>
                        3. "Start Render" option must be selected in order for render to begin.
                    </h3>

                    <img src={require('../images/renderwait.png')} style={{aspectRatio:"1/1", width:"min(90vw, 800px)", height:"auto", objectFit: "cover", zIndex:"0", padding:"5% 0% 5% 0%"}}></img>  
                    <h3 style={{textAlign:"left", width:"min(90vw, 800px)", fontSize:"calc(1rem + .6vw)"}}>
                        Note: 
                        <br/>
                        Render may take anywhere from 1-50 minutes. <br/>
                        Browser might prompt to "wait" or "exit page". <br/>
                        You must wait.
                    </h3>
                    <img src={require('../images/rendered.png')} style={{aspectRatio:"1/1", width:"min(90vw, 800px)", height:"auto", objectFit: "cover", zIndex:"0", padding:"5% 0% 5% 0%"}}></img>  
                    <h3 style={{textAlign:"left", width:"min(90vw, 800px)", fontSize:"calc(1rem + .6vw)"}}>
                        Example of completed render in the figure above.
                    </h3>
                </Stack>
            
            </div>
        
        </div>
    );
}



