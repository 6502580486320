import "../css/styles.css";
import "../css/background.scss"

import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from "react-router-dom";

import Carousel from 'react-bootstrap/Carousel';
import Button from 'react-bootstrap/Button';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

export default function Main() { 
  const navigate = useNavigate();

  const onClickHandler = async () => {
    const response = await fetch("https://marielenaperez.com/airbnb");

    const body = await response.json();

    const msg = await body.message;
    alert(await msg)
  }

  const onClickRaytracer = async () => {
    navigate("/Raytracer")
  }

  const onClickPlayer= async () => {
    navigate("/Player")
  }

  const onClickWebCam= async () => {
    navigate("/WebCam")
  }

  return (
    <div class="bubbles" style={{height:"100vh", width:"100vw"}}>
        <div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        </div>

        <Tabs
          defaultActiveKey="profile"
          id="uncontrolled-tab-example"
          className="mb-3"
          style={{margin:"auto"}}
          justify
        >
          <Tab eventKey="projects" title="Projects" style={{overflow:"visible"}}>
            <div  style={{paddingTop:"5%", overflow:"visible"}}>
              <div style={{textAlign:"center"}}>
                <h3>
                  Showcasing 3 Projects<br/>
                </h3>                
                <h4>
                  click on project name to go to project instruction site<br/>
                </h4>
              </div>

              <div style={{paddingTop:"5%", paddingLeft:"10%", paddingRight:"10%", paddingBottom:"10%", height:"550px", width:"100%"}}>
                <Carousel>
                  <Carousel.Item>
                    <img src={require('../images/img1.jpg')}></img>
                    <Carousel.Caption style={{backgroundColor:"rgba(0, 0, 0, 0.5)"}}>
                      <div style={{paddingLeft:"5%", paddingRight:"5%"}}>
                        <Button className="mb-3" onClick={onClickHandler} variant="outline-light">
                          MERN Stack
                        </Button>
                      </div>
                      <p>Technology stack: MongoDB, Express.js, React.js, Node.js</p>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <img src={require('../images/img2.jpg')}></img>
                    <Carousel.Caption style={{backgroundColor:"rgba(0, 0, 0, 0.5)"}}>
                      <div style={{paddingLeft:"5%", paddingRight:"5%"}}>
                        <Button className="mb-3" onClick={onClickRaytracer} variant="outline-light">
                          Cornell Box Raytracer                    
                        </Button>
                      </div>
                      <p>Technology stack: JavaScript, WebGL</p>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <img src={require('../images/img3.jpg')}></img>
                    <Carousel.Caption style={{backgroundColor:"rgba(0, 0, 0, 0.5)"}}>
                      <div style={{paddingLeft:"5%", paddingRight:"5%"}}>
                        <Button className="mb-3" onClick={onClickHandler} variant="outline-light">
                          Drone Swarm Search & Rescue
                        </Button>
                      </div>
                      <p>Technology stack: Unreal Engine, Airsim, YOLOv5, PyTorch, Docker</p>
                    </Carousel.Caption>
                  </Carousel.Item>
                </Carousel>
              </div>
            </div> 
          </Tab>
          <Tab eventKey="about-me" title="About" style={{textAlign:"center"}}>
            <h3 style={{paddingTop:"5%", paddingLeft:"10%", paddingRight:"10%", paddingBottom:"10%", height:"550px", width:"100%", fontFamily: "'Courier New', Courier, monospace"}}>
              Hello! Thank you for visiting my page.<br/><br/>
              3 years of professional experience in software engineering. Background in machine learning and computer vision. Interests in computer graphics and augmented/virtual reality applications. <br/>
            </h3>
          </Tab>
          <Tab eventKey="contact" title="Contact" /* disabled */>
            <div style={{paddingTop:"5%", paddingLeft:"10%", paddingRight:"10%", paddingBottom:"10%", height:"550px", width:"100%", fontFamily: "'Courier New', Courier, monospace"}}>
              <h3 style={{overflow:"auto"}}>
                Visit my LinkedIn<br/>
                <a href="https://www.linkedin.com/in/marielenaperez26/" target="_blank" style={{color:"hsla(183, 94%, 76%, 0.9)"}}>
                https://www.linkedin.com/in/marielenaperez26/<br/><br/>
                </a>
                Email<br/>
                mary26pz@gmail.com<br/><br/>
                Phone<br/>
                (786) 537 - 2380<br/><br/>
              </h3>
              <Button className="mb-3" onClick={onClickPlayer} variant="outline-light">
                          Player                    
              </Button>
              &nbsp;
              <Button className="mb-3" onClick={onClickWebCam} variant="outline-light">
                          WebCam                    
              </Button>
            </div>
          </Tab>
        </Tabs>

    </div>
  );
}
