import React from 'react';
import "./css/styles.css";
import { BrowserRouter as Router, Route, Navigate, Routes } from 'react-router-dom';

import {LogIn} from './pages/LogIn';
import Main from './pages/Main';
import AudioPlayer from './pages/AudioPlayer';
import Pitch from './pages/Pitch';
import Raytracer from './pages/Raytracer';
import WebCam from './pages/WebCam';

function App() {

    return (JSON.parse(window.localStorage.getItem("authentication")) ? 
        
            <Router >
                <Routes>
                    <Route exact path='/' element={<Main/>}/>
                    <Route exact path='/Raytracer' element={<Raytracer/>}/>
                    <Route exact path='/Pitch' element={<Pitch/>}/>
                    <Route exact path='/Player' element={<AudioPlayer/>}/>
                    <Route exact path='/WebCam' element={<WebCam/>}/>
                    <Route path="*" element={<Navigate to="/"/>} />
                </Routes>  
            </Router>
            
        : 
            <Router >
                <Routes>
                    <Route exact path='/' element={<LogIn />}/>
                    <Route path="*" element={<Navigate to="/"/>} />
                </Routes>  
            </Router>
        
    );
}

export default App;