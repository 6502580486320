import React, { useRef, useState, useEffect } from 'react'

export const Canvas = props => {
  
  const canvasRef = useRef(null)
  const [t, setT] = useState(0)
  const [canvas, setCanvas] = useState()
  const [ctx, setCtx] = useState()


useEffect(()=>{
    setCanvas(canvasRef.current)
}, [])

useEffect(()=>{
    if(canvas!=null){
        setCtx(canvas.getContext('2d'))
    }
}, [canvas])


useEffect(()=>{
    if(ctx!=null){
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)
        ctx.fillStyle = '#000000'
        ctx.beginPath()
        ctx.moveTo(0, 0)
    }
}, [ctx])

useEffect(()=>{
    if(ctx!=null){
        let y = 600-parseInt(props.order[props.midi]*5)
        ctx.lineTo(t, y)
        console.log("("+t+", "+y+")")
        ctx.stroke()
        setT(t+10)
    }

},[props.midiChanged, props.midi])

useEffect(()=>{
    console.log(props.midiChanged)

},[props.midiChanged])

useEffect(()=>{
    if(ctx!=null){
        ctx.stroke()
    }
},[ctx])


  return <canvas ref={canvasRef} {...props} style={{backgroundColor:"green"}}/>
}